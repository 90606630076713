export default {
    account: {
        more: {
            elail_login: "इमेल",
            phone_login: "फोन",
            elail_account: "इमेल",
            phone_account: "फोन",
            service: "पसंदीदा सेव",
            moreMin: "न्यूनतम पैसा निकाले मात्रा से कम नहीं होना चाहिए",
            placePrefix: "कृपया देश कोड चुनें",
        },
        aboutUs: {
            lint1: "At CoinsBank, we look at investing differently. Today's financial system is complex, exclusive and expensive, making it difficult for the average person to start investing.",
            lint2: "So we're building a platform without complex barriers, a platform that enables you to see new money possibilities and help them become a reality.",
            lint3: "CoinsBank is designed to make investing accessible to everyone, so from day one our goal has been to enable novices or seasoned experts to invest in the cryptocurrencies they want with easy-to-use financial products.",
            lint4: "CoinsBank strictly abides by the laws and regulations of various regions. We operate our businesses based on various VASP registrations, as well as MiFID II, E-Money and PSD II licences amongst all our core markets. ",
            lint5: "CoinsBank is one of the fastest growing fintech companies. We have a world-class expert team and the best technical talents to ensure that users' digital assets are always their own and to consolidate our position in the cryptocurrency trading market.",
        },
        billTypes: [
            {v: 101, name: 'फिर चार्ज'},
            {v: 102, name: 'जोड़ें'},
            {v: 103, name: 'फ्रीज़'},
            {v: 104, name: 'टाविंग'},
            {v: 105, name: 'जोड़ें'},
            {v: 106, name: 'जोड़ें'},
            {v: 201, name: 'हटाएँ'},
            {v: 202, name: 'घटाना'},
            {v: 203, name: 'विडडवाल'},
            {v: 204, name: 'वाटडवाल असफल'},
            {v: 205, name: 'हटाएँगे हैंडलिंग फी'},
            {v: 206, name: 'बाहर स्थानान्तरण'},
            {v: 207, name: 'में स्थानान्तरण किया गया'},
            {v: 208, name: 'मुद्रा स्थानान्तरण बाहर'},
            {v: 209, name: 'मुद्रा स्थानान्तरण'},
            {v: 301, name: 'मुद्रा हैंडलिंग फीड'},
            {v: 302, name: 'संपर्क इनकम'},
            {v: 303, name: 'संपर्क गुमाव'},
            {v: 311, name: 'खरीद विकल्प'},
            {v: 312, name: 'विकल्प वापस होता है'},
            {v: 313, name: 'विकल्प पुनरावृत्ति'},
            {v: 314, name: 'विकल्प हैंडलिंग फी'},
            {v: 315, name: 'मुद्रा फ्रीज़ड खरीद करें'},
            {v: 316, name: 'मुद्रा खरीदा डीडुक्शन'},
            {v: 317, name: 'मुद्रा'},
            {v: 318, name: 'खाता में कोइन खरीदी'},
            {v: 319, name: 'खाता में कोइन खरीदी'},
            {v: 320, name: 'मुद्रा विक्रेता फ्रीज़'},
            {v: 321, name: 'मुद्रा विक्रिया डीडुक्शन'},
            {v: 322, name: 'मुद्रा विक्रिया'},
            {v: 323, name: 'मुद्रा खाता में विक्रिया'},
            {v: 324, name: 'मुद्रा खाता में बेचा गया'},
            {v: 325, name: 'मुद्रा हैंडलिंग फीड'},
            {v: 401, name: 'मिनिंग मशीन जोड़ाया'},
            {v: 402, name: 'माइनर वापस'},
            {v: 403, name: 'मिनिंग मशीनरी रिवेन्यूज़'},
            {v: 404, name: 'मिनिंग मशीन बाहर होना'},
            {v: 405, name: 'मिनिंग मशीन बाहर होने के फी'},
            {v: 501, name: 'ब्लैंड बाक्स खरीदी'},
            {v: 502, name: 'ब्लैंड बाक्स आगत'},
        ],
        withdrawTypes: [
            { v: 0, name: 'लागू कर रहा है' },
            { v: 1, name: 'प्रक्रिया कर रहा है' },
            { v: 2, name: 'सफलता' },
            { v: 3, name: 'असफल' },
        ],
        types: [
            { v: 4, name: 'विकल्प खाता' },
            { v: 3, name: 'नियमित मुद्रा खाता' },
            { v: 2, name: 'संपर्क खाता' },
            { v: 1, name: 'खाता' },
        ],
        login: {
            login: "लागइन",
            selectLanguage: "भाषा चुनें",
            placeEmail: "इमेल",
            placePsd: "पासवर्ड",
            noAccount: "कोई खाता नहीं? जाएँ",
            register: "रेजिस्टर",
            btnLogin: "लॉगइन",
            forget: "भूलें?",
        },
        register: {
            register: "रेजिस्टर",
            selectLanguage: "भाषा चुनें",
            placeEmail: "इमेल",
            placePsd: "पासवर्ड",
            placeCrmPsd: "पासवर्ड पुष्टि करें",
            placeInviteId: "निमन्त्रण कोड",
            haveAccount: "पहले से एक खाता है? जाएँ",
            login: "लागइन",
            btnRegister: "रेजिस्टर",
            getCode: "सत्यापन कोड प्राप्त करें",
            code: "परीक्षण कोड",
            psd_error: "कृपया पासवर्ड फिर भरें",
            psd_error2: "दो पासवर्ड मिलाने नहीं है",
            email_error: "इमेल खाली नहीं है!",
            email_error2: "गलत इमेल प्रारूप!",
            elail_register: "इमेल",
            phone_register: "फोन",
            country: "देश",
            placePhone: "फोन",
            countryCode: "देश कोड",
            pleaseEnter: "कृपया भरें"
        },
        forget: {
            title: "पासवर्ड भूल गया",
            labelEmail: "इमेल",
            labelEmailCode: "इमेल परीक्षण कोड",
            placeEmailCode: "इमेल परीक्षण कोड",
        },
        forgetPay: {
            title: "ट्रांसेक्शन पासवर्ड भूल गया",
        }
    },
    Blind_box: {
        Blind_box_record: {
            title: "लोटेरी रेकॉर्ड",
        },
        Blind_box_voucher: {
            title: "वुचेर",
            voucher: "वुचेर",
            currentVoucher: "चाहने के लिए नफरत है",
        },
        Blind_box: {
            title: "ब्लैंड बाक्स",
            goodluck: "अच्छा भाग्य",
            panelTit: "कृपया नीचे एक अंधा बक्सा चुनें",
            tip1: "1 ड्राउन के लिए आपको 2500 कूपोन जीता होगा",
            tip2: "तुम्हारे पास 2500 बार है",
            limit1: "आज रेखाचित्र",
            limit2: "10 बार",
            times: "सेकंड",
            draw: "पेंटिंग",
            discount: "डिस्कॉन्ट",
            payable: "पैजेबल",
            blind_box: "ब्लैंड बाक्स",
            available: "उपलब्ध",
            lotteryRecord: "लोटेरी रेकॉर्ड",
            Determine_blind_box: "अंधा बक्सा निर्धारित करें",
            tip3: "2. एक लोटेरी ड्राइवर प्रति दिन 10 गुना सीमित है, और 5 से अधिक प्रतिनिधि ड्राइवर स्वीकार नहीं है",
            tip4: "3. EXCHANGE पर BTC तथा ETH के लिए रिडेम्प्शन कुपोन पुनर्वारित कर सकते हैं",
            tip5: "4. प्रत्येक लोट्री प्रतिज्ञा जीतेगा",
            tip6: "5. १००० बार ज़्यादा USDT निकालने के लिए एक 'मुक्ति वाचर' प्राप्त करना चाहिए, १००० USDT निकालने के लिए 5000 बार ज़्यादा होने के लिए एक 'रूपांतरित वाचर' प्राप्",
            tip7: "$5 लोटेरी बोनास और संभावना:",
            tip8: "1. 50% की संभावना के साथ 15-200 OTD",
            tip9: "2. 1- 5 EOS 10% की संभावना के साथ",
            tip10: "3. 5- 30XRP 10% की संभावना के साथ",
            tip11: "4. 10% की संभावना के साथ 1- 10 USDT",
            tip12: "5. 10~50PAGE, 10% की संभावना",
            tip13: "6. 5% की संभावना के साथ 3 ETH",
            tip14: "7. 10 ETH 2% की संभावना के साथ",
            tip15: "8. 20ETH 2% की संभावना के साथ",
            tip16: "9. 2 बीटीसी, संभावना 1%",
            tip17: "10 USDT लोटेरी बोनास और संभावना:",
            tip18: "1. 30~600 OTD, संभावना 50%",
            tip19: "2. 1~20 EOS 10% की संभावना के साथ",
            tip20: "3. 10~80XRP की संभावना 10%",
            tip21: "4. 5-30 USD/ton, 10% की संभावना के साथ",
            tip22: "5. 20~100PAGE, 10% की संभावना",
            tip23: "6. 4% की संभावना के साथ 20 ETH",
            tip24: "7. 4% की संभावना के साथ 1 बीटीसी",
            tip25: "8. 2 BTC, संभावना 2%",
            tip26: "20 USDT लोटेरी बोनास और संभावना:",
            tip27: "1. 5-50USDT 30% की संभावना के साथ",
            tip28: "2. 1- 50 एडीए 30% की संभावना के साथ",
            tip29: "3. 1~20 EOS 10% की संभावना के साथ",
            tip30: "4. 3DOT 5% की संभावना के साथ",
            tip31: "5. 1 एक्सएमआर 5% की संभावना के साथ",
            tip32: "6. 5% की संभावना के साथ 1 बीसीएच",
            tip33: "7. 5% की संभावना के साथ 1 ETH",
            tip34: "8. 10000 DOGE 5% की संभावना के साथ",
            tip35: "9. 2 बीटीसी, 3% संभावना",
            tip36: "10. 5 बीटीसी 2% की संभावना के साथ",
            tip37: "लास्की ड्राव विधि:",
            tip38: "1. 5 USDT के लिए एक बार, 10 USDT के लिए दुई बार, 20 USDT के लिए एक बार, और 5 प्रतिक्रिया डिस्कॉन्ट के लिए 5% डिस्कॉन्ट सुखी करें",
        },
    },
    Financial: {
        index: {
            finance: "आर्थिक",
            ieo: "IEO",
            LockMining: "ताला लगाया गया मिनिंग",
            day: "दिन",
            unit: "उठाएँ",
            Daily_yield: "उठाएँ",
        },
    },
    Follow: {
        index: {
            title: "ट्रेडर्स की सूची",
            tip: "डाटा हर घंटा अद्यतन होता है",
        },
    },
    Fund: {
        Assets_all_record: {
            title: "आर्थिक रेकॉर्ड",
            number: "मात्रा",
            time: "समय",
        },
        Assets_record: {
            title: "खाता विवरण",
            total_assets: "कुल एसेट परिवर्तित",
            Available_Credit: "उपलब्ध क्रीडिट",
            locking: "ताला लगाता है",
            Financial_records: "आर्थिक रेकॉर्ड",
            number: "मात्रा",
            time: "समय",
        },
        Assets: {
            Account_record: "खाता बदलें",
            Recharge: "फिर चार्ज करें",
            Collection: "संग्रह",
            Withdrawal: "पैसा हटाएँ",
            transfer: "खाता स्थानान्तरण करें",
            Currency_transfer: "मुद्रा स्थानान्तरण",
            Coins: "मुद्रा",
            contract: "कंट्रांट",
            Legal_currency: "नियमित मुद्रा",
            option: "विकल्प",
            Equivalent: "बराबर",
        },
        FundTransfer: {
            title: "राजधानी स्थानान्तरण",
            Select_Currency: "मुद्रा चुनें",
            from: "से",
            Transfer_quantity: "स्थानान्तरण मात्रा",
            to: "में",
        },
        Receive_withdraw_record: {
            title: "रिकार्ड",
        },
        Receive: {
            title: "फिर चार्ज करें",
            Digital_currency: "डिजिटल मुद्रा",
            tip: "पुनरार्ज पता के लिए ग्राहक सेवा संपर्क करें",
            next: "अगला चरण",
            Recharge_address: "पता फिर चार्ज करें",
            Recharge_number: "Recharge quantity",
            place_Recharge_number: "कृपया पुनरार्ज मात्रा भरें",
            Payment_voucher: "पैमेंट वाचर",
            place_Payment_voucher: "कृपया पैसा वाचर भरें",
        },
        Transfer: {
            Currency_transfer: "मुद्रा स्थानान्तरण",
            Capital_transfer: "राजधानी स्थानान्तरण",
            from: "से",
            Transfer_number: "स्थानान्तरण मात्रा",
            need_service_charge: "अतिरिक्त सेवा चार्ज",
            to: "में",
            will_get: "आप मिलेंगे",
            Available_Credit: "उपलब्ध क्रीडिट",
            Exchange_rate: "एक्सचेंज दर",
            Service_Charge: "सेवा चार्ज",
        },
        Withdraw: {
            title: "खाता स्थानान्तरण करें",
            Withdrawal: "पैसा हटाएँ",
            bank_card: "बैंक कार्ड",
            wallet_address: "मेन्ट्शन वालेट पता",
            Withdrawal_number: "हटाएँ",
            available: "उपलब्ध",
            place_Withdrawal_number: "कृपया प्रतिक्रिया मात्रा भरें",
            all: "पूर्ण",
            Minimum_Withdrawal_number: "न्यूनतम घटाने की मात्रा",
            password: "खाता पासवर्ड",
            Service_Charge: "सेवा चार्ज",
        },
    },
    Guess: {
        title: "लोटेरी",
        min: "न्यूनतम",
        Bullish: "उठाएँ",
        Bearish: "पड़ें",
        Guess_amount: "अनुमानित मात्रा",
        place_Guess_amount: "कृपया मात्रा भरें",
        Current_selection: "वर्तमान चयन",
        Payable: "पैजेबल",
        Guess_now: "अब धान्य",
        Available: "उपलब्ध",
        Lottery_record: "लोटेरी रेकॉर्ड",
        Betting_record: "रिकार्ड बेटिंग",
        Start_price: "प्रारंभ किया जा रहा है",
        End_price: "अंतिम मूल्य",
        Game_introduction: "खेल का परिचय",
        tip1: "1. परीक्षा तीन मिनटों में विभाजित है: 10 मिनट, 3 मिनट, और 1 मिनट. बीटीसी का उठाना और गिराना. बीटीसी की मूल्य कोइन्बेस, बिनान्स और हुओबी की मूल्य है, जो पूर्णतः साधारण कादंबरी है।",
        tip2: "2. यहाँ 10 मिनिट को उदाहरण के रूप में ले जाओ: हर सत्र के पहले 10 मिनिट का अनुक्रमण समय है, अंतिम 2 मिनिट का अनुक्रमण समय है, अंतिम 1 मिनिट का अनुक्रमण की मूल्य है, बीडिंग क्रिया की निर्दिष्ट क्रिया 'कम' है, उदाहरण के लिए: 00: 00~00: 15,यह दिन का पहिला अवधि है, और 00: 00 में, आपने 1000 USDT के क्रम के साथ BTC 'bullish' क्रम रखा है. जब समय 00: 13 तक पहुँचता है, बीटीसी की कूल 35000 यूएसडीटी है, तो तुम्हारे चिह्नित कूल है: 35000 यूएसडीटी है, और परिणाम कूल 00: 14 में बताया गया है 350001 यूएसडीटी है, तो अनपेक्षा है 'ऊपर', तुम्हारी स्पेक्यूलेशन के बारे में अभिनन्दन. आप $950 के लाभ मिलेंगे. तंत्र तुम्हारे लिए १९५० अमेरिका डॉलर टी स्थापित करेगा, जिनमें से ५० अमेरिका डॉलर टी प्लेटफ़ॉर्म है, और आप 950 अमेरि",
        tip3: "3. संभावना 1:0.95 हैं, क्यों 1:1 नहीं है? क्योंकि प्लेटफ़र्म को 5% हैंडलिंग फीड की आवश्यकता है.",
        tip4: "4. परीक्षा के बाद, परिणाम सुधारित होगा. जो पैसा तुम जीते हो वह तुम्हें घाटे में दिया जाता है। इसे सुनिश्चित करने के लिए प्लेटफ़ॉर्म उपयोक्ताओं के बीच खेलाने के व्यवहार में सहभागी नहीं होती, यदि कुछ विजेताओं और कुछ घाटे में हैं, उदाहरण के लिए, जब आप १००००० यूएसडीटी से बेट लेते हैं और आप जीते हैं, आपको 95000 यूएसडीटी प्राप्त करना चाहिए। हालाँकि केवल 80000 यूएसडीटी हराम हो गया है, और पैसा पुल फ़ंडस तुम्हारे 95000 यूएसडीटी के लिए पैसा करने के लिए पर्याप्त नह आपको प्रदान पुल के लिए इंतजार करना होगा कि हराम करनेवालों के फ़ंड दिखाएँ. जब आप 15000 USDT तक पहुँचते हैं, प्लेटफ़ॉर्म अपने 95000 बोनुस स्थापित करेगा。",
        tip5: "5. यदि आप वास्तव में बोनास जल्दी लेने के लिए इंतजार नहीं कर सकते, तो आप तेजी प्रदान के साथ जारी कर सकते हैं, लेकिन तेजी प्रदान केवल 95000 USDT के 25% प्राप्त कर सकता है, जो अर्थ यदि पावर प्लेटफ़ॉर्म में स्थानान्तरण किया जाता है, तो प्लेटफ़ॉर्म को सापेक्षिक जोखियों को उठाने की जरूरत है, तो。",
        tip6: "ये क्यूए प्लेटफ़ॉर्म और उपयोक्ताओं के बीच खेल नहीं है. यदि आप ऐसी स्थिति मिल सकते हैं, तुरंत रिपोर्ट कर सकते हैं.",
    },
    home: {
        title: "",
        Total_assets: "कुल एसेट परिवर्तित",
        Recharge: "फिर चार्ज करें",
        documentary: "दस्तावेज़",
        transaction: "व्यवहार करें",
        IEO: "IEO",
        Lock_mining: "ताला लगाया गया मिनिंग",
        Investment_Financing: "निवेश और फ़िनांसिंग",
        Currency_transfer: "मुद्रा स्थानान्तरण",
        Currency_swap: "स्वाप",
        Withdrawal: "पैसा हटाएँ",
        Blind_box: "ब्लैंड बाक्स",
        Guess: "अनुमान",
        Daily_tasks: "रोज़ी कार्य",
        Sign_task: "कार्य हस्ताक्षर करें",
        Buy_coins_quickly: "जल्दी कोनिस खरीद करें",
        Buy_quickly: "तेज खरीद करें",
        day: "दिन",
        rise: "उठाएँ",
        Daily_yield: "दिन",
        quotation: "उद्धरण",
        Transaction_pair: "ट्रांसेक्शन जोड़ा",
        Latest_price: "अन्तिम मूल्य",
        Rise_fall: "आज बढ़ने वाले हैं",
    },
    ieo: {
        ieo: "आईओ सदस्यता",
        Lock_up_cycle: "तालाबन्द अवधि",
        day: "दिन",
        Closed: "बन्द",
        Completed: "पूर्ण",
        Subscribed: "सदस्यता",
        surplus: "उत्पन्न",
        My_subscription: "मेरा सदस्यता",
        Subscription_time: "सदस्यता समय",
        currency: "मुद्रा",
        Requested_number: "अनुप्रयोग की संख्या",
        Pass_number: "पासवर्ड की संख्या",
        Market_time: "बाजार के लिए समय",
    },
    Invest: {
        conduct: "आर्थिक क्रिया",
        Special_area: "जोन",
        Annualized_rate: "वापस का वर्षीकृत दर",
        Linked_price: "लिंकेड रेफरेंस मूल्य",
        Remaining_share: "बाकी साझेदार",
        Position_duration: "स्थिति अवधि",
        due_date: "दिनांक तिथि",
        My_conduct: "मेरा आर्थिक प्रबंधन",
        all: "पूर्ण",
        fail: "असफल",
        Purchased: "खरीद किया गया",
        settlement: "सेलेंट में",
        Completed: "पूर्ण",
    },
    Lockming: {
        Minimum_single: "न्यूनतम एकल ट्रांसेक्शन",
        Daily_yield: "दिन",
        Lock_up_cycle: "तालाबन्द अवधि",
        payout_time: "विभाजन पैसा का समय",
        everyday: "हर दिन",
        Custody_funds: "एस्क्रॉव फ़ंड्स",
        Return_expiration: "मियाद परित्याग",
        called_away: "दूर बुलाया गया",
        placeholder: "कृपया सामग्री भरें",
        all: "पूर्ण",
        Estimated_income: "अनुमानित आगत",
        Available_assets: "उपलब्ध सामग्री",
        subscription: "सदस्यता",
        Lock_mining: "ताला लगाया गया मिनिंग",
        Funds_custody: "संरक्षण के अनुसार फंड",
        Estimated_yield_today: "आज अपेक्षित प्रदान",
        Accumulated_income: "सम्पूर्ण आयात",
        Orders_custody: "क्रमबद्ध",
        Delegated_Order: "प्रविष्टित क्रम",
        Lock: "लॉकअप",
        day: "दिन",
        Lock_list: "लॉक सूची",
        Exited: "बाहर हो गया",
        Redeemed: "Redeemed",
        have_hand: "हाथ में है",
        redeem: "पुनरावृत्ति",
        Amount_currency: "डेपोसिट मात्रा",
        Lockup_time: "समय तालाबन्द करें",
        Expiration_time: "मियाद समय",
        early_redemption: "प्रारंभिक मुक्ति के लिए जीवित किया गया है",
    },
    Market: {
        Optional: "स्वयं चयन",
        Transaction_pair: "ट्रांसेक्शन जोड़ा",
        Latest_price: "अन्तिम मूल्य",
        place_search: "मुद्रा",
    },
    Setting: {
        label_currency: "मुद्रा चुनें",
        place_currency: "कृपया मुद्रा चुनें",
        label_walletTitle: "वालेट नाम",
        place_walletTitle: "कृपया वालेट नाम भरें",
        label_walletAddress: "वालेट पता",
        place_walletAddress: "कृपया वालेट पता भरें",
        Add_bankCard: "बैंक कार्ड जोड़ें",
        label_name: "Real name",
        label_depositBank: "डोपोसिट की बैंक",
        label_bankCard: "बैंक कार्ड क्रमांक",
        label_beneficary_address: "परीक्षण कोड",
        label_payee_name: "पैसा का नाम",
        label_bank_name: "बैंक नाम",
        label_swift_code: "बैंक कोड",
        APP_download: "एमएसबी प्रमाणपत्र",
        label_currentPassword: "मौजूदा पासवर्ड",
        place_currentPassword: "कृपया मौजूदा पासवर्ड भरें",
        label_newPassword: "नया लॉगइन पासवर्ड",
        place_newPassword: "कृपया एक नया लॉगइन पासवर्ड भरें",
        label_confirmationPassword: "नया पासवर्ड यकीन करें",
        place_confirmationPassword: "कृपया पुष्टि करने के लिए नया पासवर्ड भरें",
        Real_mark1: "व्यक्तिगत पहचान प्रमाणीकरण",
        Real_mark2: "सत्यापन के बाद उपलब्ध अनुमतिया:",
        Real_mark3: "Lv1.मूल प्रमाणपत्र",
        Real_mark4: "प्रमाणपत्र के पश्चात यह प्रतिबंधित किया जा सकता है, और 24 घण्टा सीमा 200BTC है",
        Real_mark5: "सत्यापन के पश्चात व्यवस्था मुद्रा ट्रांसेक्शन के साथ, 2000USDT के एकल सीमा के साथ कर सकता है",
        Real_mark6: "Lv2.उन्नत प्रमाणपत्र",
        Real_mark7: "क्रेडिट सीमा बढ़ाएं, २४ घंटे के लिए 500BTC",
        Real_mark8: "व्यवस्था मुद्रा ट्रांसेक्शन सीमा, 1000000USDT के एकल सीमा के साथ बढ़ाएँ",
        Real_mark9: "वार्म प्रोम्प्ट: उपयोक्ता के फ़ंड की सुरक्षा के लिए, कृपया पहले इमेल और फोन की बाइन्डिंग को पूरा करें, अन्यथा आपका",
        Real_mark10: "मूल प्रमाणपत्र",
        Real_mark11: "प्रमाणपत्र",
        Real_mark12: "उन्नत प्रमाणपत्र",
        Real_mark13: "प्रमाणपत्र",
        label_realName: "पूरा नाम",
        message_realName: "कृपया अपना वास्तविक नाम भरें",
        label_cardNumber: "आईडी संख्या",
        message_cardNumber: "कृपया आईडी संख्या प्रविष्ट करें",
        upload_front: "आईडी कार्ड के आगे अपलोड करें",
        upload_back: "आईडी कार्ड की बैक अपलोड करें",
        upload_people: "हैंडहेल्ड आईडी कार्ड अपलोड करें",
        under_review: "पुनरावृत्ति",
        security_setting: "सुरक्षा सेटिंग",
        my_bank: "मेरा बैंक कार्ड",
        Change_Loginpsd: "लॉगइन पासवर्ड बदलें",
        Change_transactionpsd: "लॉगइन पासवर्ड बदलें",
        email: "मेलबाक्स",
        my: "मेरा",
        Bank: "बैंक कार्ड",
        AddBank: "बैंक कार्ड जोड़ें",
        walletAddress: "वालेट पता",
        Default_currency: "मूलभूत वैध मुद्रा",
        language: "language",
        share: "साझेदार",
        logout: "हस्ताक्षर करें",
        qrcode: "मेरा Qrcode",
        personal: "व्यक्तिगत जानकारी",
        identity: "पहचान प्रमाणीकरण",
        nickname: "उपनाम",
        nickname_tip: "2- 10 अक्षर, चीनी, अंग्रेजी और संख्याओं को समर्थन करते हैं",
        Add_newAccount: "नवीन खाता जोड़ें",
        label_user: "प्रयोक्ता नाम",
        label_number: "बैंक कार्ड क्रमांक",
        label_branch: "बैंक पता",
        label_bank: "बैंक नाम",
        label_swift: "स्विफ्ट एनकोडिंग",
        label_address: "प्रयोक्ता पता",
    },
    Task: {
        daily_tasks: "रोज़ी कार्य",
        activity_today: "आज की घटना",
        task_list: "कार्य सूची",
        weekly_tasks: "सप्ताह कार्य",
        activity: "सक्रिया",
        undone: "अपूर्ण",
        receive: "receive",
        task_rules: "कार्य नियम",
        task_rules1: "1： क्रियाएँ एक सप्ताह में जमा हुए और स्टैक हुए हैं, और प्रत्येक सोमवार 0 में स्वचालित रूप साफ कर दिया जाता है",
        task_rules2: "2： प्रत्येक दिन सभी 4 दिन कार्य को पूरा करो और 100 क्रिया स्तर तक पहुँचने के बाद 5 सीएसी कोनी प्राप्त करें;",
        task_rules3: "3： जब क्रिया 350 तक पहुँचती है, तो आप 10 सीएसी कोनी प्राप्त कर सकते हैं",
        task_rules4: "4： जब क्रिया 700 तक पहुँचती है, तो आप एक दिन के लिए N1 माइनर के अनुभव मिल सकते हैं",
        sign_task: "लॉगिन कार्य",
        Sign_everyDay: "प्रत्येक दिन लगइन करें",
        CCA_coin: "CCA कोन्ट",
        signIn: "[degrees] in [radians]",
        day: "दिन",
        daily_checkIn: "रोज़ी जाँच- इन",
        put_away: "दूर रखें",
        Expand: "विस्तारित करेंexpand",
        signIn_now: "अभी हस्ताक्षर करें",
        checkIn_rules: "नियम जाँचें",
        checkIn_rules1: "1： 5 सीसीए कॉन्ट प्राप्त होगा यदि आप 5 दिनों के लिए हस्ताक्षर करेंगे",
        checkIn_rules2: "2： २० सीएसी कोनिट प्राप्त होगा जब 14 दिनों से हस्ताक्षरित करने के बाद",
        checkIn_rules3: "3： 21 दिनों के लिए हस्ताक्षर करें और N1 माइनर को तीन दिनों अनुभव समय के साथ बदला दें",
        checkIn_rules4: "4： जाँच इन के 24 दिनों के पश्चात, जाँच इन रेकॉर्ड को अन्त्यायित रखें और प्रत्येक दिन 5 सीसीए कोनी प्राप्त करें",
    },
    Transaction: {
        delegate_List: "प्रतिनिधि सूची",
        risk_rate: "रिस्क दर",
        total_income: "कुल स्थिति आयात",
        oneClick_closing: "एक- क्लिक स्थिति बन्द कर रहा है",
        optional: "स्वयं चयन",
        all_closed: "पूरा बन्द कर रहा है",
        flat_multiple: "सिर्फ फ्लैट और बहुत से एकल",
        flat_empty: "सिर्फ फ्लैट शीट",
        purchase: "खरीद करें",
        sellOut: "बेचें",
        market_price: "बाजार मूल्य",
        fixed_price: "निश्चित मूल्य",
        trading_hands: "व्यापार हाथों की संख्या",
        multiple: "बहुत",
        bond: "बंद",
        Service_Charge: "सेवा चार्ज",
        balance: "balance",
        long: "लंबा जाएँ",
        Short: "छोटा",
        Price: "मूल्य",
        number: "मात्रा",
        type: "प्रकार",
        time: "समय",
        operation: "प्रक्रिया",
        Transaction_pair: "ट्रांसेक्शन जोड़ा",
        direction: "दिशा",
        search: "खोजें",
        Latest_price: "अन्तिम मूल्य",
        Rise_fall: "आज बढ़ने वाले हैं",
        entrust: "विश्वास",
        all: "पूर्ण",
        entrust_time: "प्रविष्टि समय",
        state: "स्थिति",
        Completed: "पूर्ण",
        air_completed: "पूर्ण",
        place_amount: "कृपया सामग्री भरें",
        turnover: "एक परिवर्तन",
        contract: "कंट्रांट",
        option: "विकल्प",
        coins: "मुद्रा",
        High_today: "आज उच्च",
        Low_today: "आज कम",
        Entrusted_bill: "प्रविष्टित रेजिस्ट्रेशन",
        Buy_more: "अधिक खरीद करें",
        Buy_less: "कम खरीद करें",
        buy: "खरीद करें",
        sell: "बेकाएँ",
        crm_order: "क्रम की प्रतिज्ञान",
        Current_price: "मौजूदा मूल्य",
        expiration_time: "समय चुनें",
        Option_position: "विकल्प स्थिति",
        position: "स्थिति",
        history: "इतिहास",
        Purchase_price: "मूल्य खरीद करें",
        Purchase_amount: "मात्रा खरीद करें",
        Order_duration: "क्रम अवधि",
        Selling_time: "विक्रेत समय",
        Profit_loss: "प्रोफाइट और हराम",
        Hand_count: "हात गणना",
        countdown: "गणना",
        order_number: "क्रम संख्या",
        currency: "मुद्रा",
        reales_Number: "वास्तविक ट्रांसेक्शन",
        total_price: "कुल मूल्य",
        close_price: "बंद कर रहा है",
        volume_trade: "व्यापार का आवाज",
        hand_fee: "हैंडिंग फी",
        continue_order: "क्रम रखने के लिए जारी रखें",
    },
    footer: {
        home: "घर पृष्ठ",
        market: "उद्धरण",
        transaction: "व्यवहार करें",
        financial: "आर्थिक",
        fund: "आसेट्स",
        rule: "नियम",
        select_language: "कृपया भाषा चुनें",
    },
    common: {
        noData: "कोई संबंधित डाटा नहीं",
        Exchange: "बदलें",
        Cancel: "रद्द करें",
        Confirm: "पुष्टि करें",
        Submit: "भेजें",
        more: "अधिक",
        add: "जोड़ें",
        Cover: "छुपाएँ",
        Success: "सफलता",
        portion: "साझेदार",
        day: "दिन",
        please_select: "कृपया चुनें",
        no_data: "कोई डाटा नहीं",
    },
    script: {
        error_msg: "संख्या/अक्षर",
        register_noAccount: "कृपया उपयोक्ता की फोन संख्या या इमेल पता भरें!",
        send_phone_scuccess: "सफलतापूर्वक SMS भेजा गया!",
        register_success: "रेजिस्टरेशन सफल!",
        please_enter_amount: "कृपया मात्रा भरें!",
        please_enter_money: "कृपया क्रिया फ़ंड्स इनपुट करें!",
        success: "सफलता!",
        bankId_msg: "कृपया एक वालेट चुनें",
        amount_msg: "कृपया मात्रा भरें",
        password_msg: "कृपया पासवर्ड प्रविष्ट करें",
        redemption_msg: "क्या आप प्रारंभिक विमोचन के बारे में सुनिश्चित हैं?",
        tip: "टिप्स",
        redemption_success: "पुनरावृत्ति सफल!",
        canceled: "रद्द",
        realname_submit_success: "वास्तविक नाम सफलतापूर्वक जमा किया गया!",
        logout_tip: "क्या आप निश्चित है कि आप लॉग आउट करना चाहते हैं?",
        login_tip: "क्या आप निश्चित है कि आप लॉग इन करना चाहते हैं?",
        signIn: "[degrees] in [radians]",
        closeout_tip: "सभी बन्द स्थितियों को यकीन करें?",
        closeout_success: "सभी स्थितियाँ सफलतापूर्वक बन्द हैं!",
        cancel_tip: "क्या आप निश्चित है कि आप रद्द करना चाहते हैं?",
        notice: "सूचना",
        insufficient_balance: "माफ़ करें, आपका क्रेडिट कम चल रहा है!",
    },
};
