export default {
    account: {
        more: {
            elail_login: "Электронная почта",
            phone_login: "Телефон",
            elail_account: "Электронная почта",
            phone_account: "Телефон",
            service: "Обслуживание клиентов",
            moreMin: "Не ниже минимальной суммы заимствования",
            placePrefix: "Выберите номер страны",
        },
        aboutUs: {
            lint1: "В CoinsBank мы по - разному смотрим на инвестиции. Сегодняшняя финансовая система является сложной, эксклюзивной и дорогостоящей, и обычным людям трудно начать инвестировать.",
            lint2: "Таким образом, мы строим платформу без сложных препятствий, платформу, которая позволит вам увидеть новые возможности финансирования и помочь им стать реальностью.",
            lint3: "CoinsBank предназначен для того, чтобы каждый мог инвестировать, поэтому с первого дня наша цель - дать новичкам или опытным экспертам возможность инвестировать в криптовалюты, которые они хотят, с помощью простых в использовании финансовых продуктов.",
            lint4: "CoinsBank строго соблюдает региональные законы и правила. Наш бизнес основан на различных регистрах VASP, а также на лицензиях MiFID II, E - Money и PSD II на всех основных рынках. ",
            lint5: "CoinsBank является одной из самых быстрорастущих финтех - компаний. У нас есть команда экспертов мирового класса и лучшие технические специалисты, чтобы убедиться, что цифровые активы пользователей всегда принадлежат им самим и укрепляют наши позиции на рынке криптовалют.",
        },
        billTypes: [
            {v: 101, name: 'Зарядка'},
            {v: 102, name: 'Депозит'},
            {v: 103, name: 'Замораживание'},
            {v: 104, name: 'Оттепель'},
            {v: 105, name: 'Депозит'},
            {v: 106, name: 'Депозит'},
            {v: 201, name: 'Замораживание средств'},
            {v: 202, name: 'Снятие'},
            {v: 203, name: 'Денежные средства успешно'},
            {v: 204, name: 'Невозможность заимствования'},
            {v: 205, name: 'Плата за снятие средств'},
            {v: 206, name: 'Вывод'},
            {v: 207, name: 'Переводы'},
            {v: 208, name: 'Денежные переводы'},
            {v: 209, name: 'Денежные переводы'},
            {v: 301, name: 'Расходы по контрактам'},
            {v: 302, name: 'Поступления по контрактам'},
            {v: 303, name: 'Контрактные потери'},
            {v: 311, name: 'Приобретение опционов'},
            {v: 312, name: 'Опционная прибыль'},
            {v: 313, name: 'Опционная прибыль'},
            {v: 314, name: 'Опционная плата'},
            {v: 315, name: 'Купить замороженные монеты'},
            {v: 316, name: 'Вычет при покупке монет'},
            {v: 317, name: 'Купить монеты'},
            {v: 318, name: 'Купить монету'},
            {v: 319, name: 'Купить монету!'},
            {v: 320, name: 'Продажа монет заморожена'},
            {v: 321, name: 'Вычет проданных монет'},
            {v: 322, name: 'Вернуть монеты'},
            {v: 323, name: 'Монеты, проданные на счет'},
            {v: 324, name: 'Монеты, проданные на счет!'},
            {v: 325, name: 'Плата за покупку монет!'},
            {v: 401, name: 'Добавлен минерал.'},
            {v: 402, name: 'Возврат рудника'},
            {v: 403, name: 'Доходы от горнодобывающей техники'},
            {v: 404, name: 'Экспорт шахтеров'},
            {v: 405, name: 'Сбор за выход из шахты'},
            {v: 501, name: 'Купить слепой ящик'},
            {v: 502, name: 'Доход от слепого ящика'},
        ],
        withdrawTypes: [
            { v: 0, name: 'Применение' },
            { v: 1, name: 'Повесь.' },
            { v: 2, name: 'Успех' },
            { v: 3, name: 'Неудача' },
        ],
        types: [
            { v: 4, name: 'Опционный счет' },
            { v: 3, name: 'Счет Fiat' },
            { v: 2, name: 'Счета контрактов' },
            { v: 1, name: 'Монетный счет' },
        ],
        login: {
            aboutUs: "О нас",
            notice: "Чтобы облегчить пополнение сервиса, пожалуйста, свяжитесь с онлайн - сервисом обслуживания клиентов.",
            login: "Регистрация",
            selectLanguage: "Язык",
            placeEmail: "Электронная почта или мобильный телефон",
            placePsd: "Язык",
            noAccount: "Нет счета? Иди.",
            register: "Регистрация",
            btnLogin: "Регистрация",
            forget: "Забыть?",
        },
        register: {
            register: "Создать учётную запись",
            selectLanguage: "Язык",
            placeEmail: "Электронная почта",
            placePsd: "Язык",
            placeCrmPsd: "Подтвердить пароль",
            placeInviteId: "Код приглашения",
            haveAccount: "Уже есть счет? Иди.",
            login: "Регистрация",
            btnRegister: "Регистрация",
            getCode: "Почта",
            code: "Код проверки",
            psd_error: "Введите пароль",
            psd_error2: "Два несовместимых пароля.",
            email_error: "Почтовый ящик не может быть пустым!",
            email_error2: "Неверный формат электронной почты!",
            elail_register: "Электронная почта",
            phone_register: "Телефон",
            country: "Государство",
            placePhone: "Телефон",
            countryCode: "Код страны / региона",
            pleaseEnter: "Введите"
        },
        forget: {
            title: "Забыть пароль",
            labelEmail: "Электронная почта",
            labelEmailCode: "Код проверки электронной почты",
            placeEmailCode: "Код проверки электронной почты",
        },
        forgetPay: {
            title: "Забыть пароль",
        }
    },
    Blind_box: {
        Blind_box_record: {
            title: "Список лотерей",
        },
        Blind_box_voucher: {
            title: "Ваучеры",
            voucher: "Ваучеры",
            currentVoucher: "Текущий документ",
        },
        Blind_box: {
            title: "Слепой ящик",
            goodluck: "Удачи.",
            panelTit: "Пожалуйста, выберите слепую коробку ниже.",
            tip1: "В лотерее должно быть 2500 купонов.",
            tip2: "У тебя еще 2500 шансов.",
            limit1: "Остальная ничья сегодня.",
            limit2: "Десять.",
            times: "Количество",
            draw: "Рисунок",
            discount: "Скидка",
            payable: "Кредиторская задолженность",
            blind_box: "Слепой ящик",
            available: "Доступные",
            lotteryRecord: "Список лотерей",
            Determine_blind_box: "Определение слепого ящика.",
            tip3: "2. Один розыгрыш лотереи ограничен 10 раз в день и 5 раз подряд.",
            tip4: "3. Купоны можно обменять на BTC и ETH в Exchang",
            tip5: "4. Каждый раз выигрыш в лотерею.",
            tip6: "5. Для 5 USDT - снятия более 10000 необходимо получить « конвертируемый ваучер», для 10 USDT - снятия более 5000 - « конвертируемый ваучер», для 20 USDT - лотерей более 2500 - « своп - ваучер».",
            tip7: "5 Долларовый выигрыш и вероятность:",
            tip8: "1. 15 ~ 200 ОТД, вероятность 50%",
            tip9: "2. 1 ~ 5 EOS с вероятностью 10%",
            tip10: "3. 5 ~ 30XRP, вероятность 10%",
            tip11: "4. От 1 до 10 USDT с вероятностью 10%",
            tip12: "5. 10 ~ 50DOGE, вероятность 10%",
            tip13: "6. 3 ETH с вероятностью 5%",
            tip14: "7. 10 ETH, вероятность 2%",
            tip15: "8. 20ETH, вероятность 2%",
            tip16: "9. 2 BTC, вероятность 1%",
            tip17: "10 USDT Награды и вероятность:",
            tip18: "1. 30~600 OTD, вероятность 50%",
            tip19: "2. 1~20EOS, вероятность 10%",
            tip20: "3. 10~80XRP, вероятность 10%",
            tip21: "4. 5~30 USDT, вероятность 10%",
            tip22: "5. 20~100DOGE, вероятность 10%",
            tip23: "6. 20 ETH, вероятность 4%",
            tip24: "7. 1 BTC, вероятность 4%",
            tip25: "8. 2 BTC, вероятность 2%",
            tip26: "Премия в 20 долларов и вероятность:",
            tip27: "1. 5~50USDT, вероятность 30%",
            tip28: "2. 1~50 ADA, вероятность 30%",
            tip29: "3. 1~20EOS, вероятность 10%",
            tip30: "4. 3DOT, вероятность 5%",
            tip31: "5. 1 XMR, вероятность 5%",
            tip32: "6. 1 BCH, вероятность 5%",
            tip33: "7. 1 ETH, вероятность 5%",
            tip34: "8. 10000 DOGE, вероятность 5%",
            tip35: "9. 2 BTC, 3% вероятность",
            tip36: "10. 5 BTC, вероятность 2%",
            tip37: "Способ лотереи:",
            tip38: "1. 5 USDT 1 раз, 10 USDT 1 раз, 20 USDT 1 раз и 5 раз подряд со скидкой 5%",
        },
    },
    Financial: {
        index: {
            finance: "Средства",
            ieo: "IEO",
            LockMining: "Заблокировать раскопки",
            day: "День",
            unit: "Повышение",
            Daily_yield: "Ежедневная норма прибыли",
        },
    },
    Follow: {
        index: {
            title: "Список трейдеров",
            tip: "Данные обновляются каждый час.",
        },
    },
    Fund: {
        Assets_all_record: {
            title: "История",
            number: "Количество",
            time: "Время",
        },
        Assets_record: {
            title: "История",
            total_assets: "Мои активы.",
            Available_Credit: "Доступные",
            locking: "Нерешенные",
            Financial_records: "История",
            number: "Количество",
            time: "Время",
        },
        Assets: {
            Account_record: "История",
            Recharge: "Депозит",
            Collection: "Сбор",
            Withdrawal: "Снятие",
            transfer: "Передача",
            Currency_transfer: "Преобразование",
            Coins: "Монеты",
            contract: "Контракты",
            Legal_currency: "Необналичивание бумажных денег",
            option: "Параметры",
            Equivalent: "Равенство",
        },
        FundTransfer: {
            title: "Перевод средств",
            Select_Currency: "Выбрать валюту",
            from: "Начиная с...",
            Transfer_quantity: "Количество переводов",
            to: "А",
        },
        Receive_withdraw_record: {
            title: "Запись",
        },
        Receive: {
            title: "Депозит",
            Digital_currency: "Криптовалюты",
            tip: "Свяжитесь с клиентом запросить адрес пополнения",
            next: "Следующий шаг",
            Recharge_address: "Адрес кошелька",
            Recharge_number: "Сумма вкладов",
            place_Recharge_number: "Введите сумму депозита.",
            Payment_voucher: "Платежная квитанция",
            place_Payment_voucher: "Пожалуйста, введите квитанцию.",
        },
        Transfer: {
            Currency_transfer: "Преобразование",
            Capital_transfer: "Перевод средств",
            from: "Начиная с...",
            Transfer_number: "Количество",
            need_service_charge: "Свободный",
            to: "А",
            will_get: "Вы получите",
            Available_Credit: "Доступные кредиты",
            Exchange_rate: "Обменный курс",
            Service_Charge: "Расходы на обслуживание",
        },
        Withdraw: {
            title: "Снятие",
            Withdrawal: "Снятие",
            bank_card: "Банковские карточки",
            wallet_address: "Адрес кошелька",
            Withdrawal_number: "Сумма заимствования",
            available: "Доступные",
            place_Withdrawal_number: "Пожалуйста, введите количество денег.",
            all: "Все",
            Minimum_Withdrawal_number: "Минимальная сумма заимствования",
            password: "Код вывода",
            Service_Charge: "Расходы на обслуживание",
        },
    },
    Guess: {
        title: "Угадай.",
        min: "Минимум",
        Bullish: "Надругательство над слабыми",
        Bearish: "Надругательство над слабыми",
        Guess_amount: "Угадайте сумму",
        place_Guess_amount: "Введите сумму",
        Current_selection: "Текущий выбор",
        Payable: "Кредиторская задолженность",
        Guess_now: "Теперь угадай.",
        Available: "Доступные",
        Lottery_record: "Список лотерей",
        Betting_record: "Запись ставок",
        Start_price: "Начальная цена",
        End_price: "Конечная цена",
        Game_introduction: "Описание игры",
        tip1: "1. Тест состоит из трех этапов: 10 минут, 3 минуты и 1 минута. BTC растет и падает. Цена BTC - это средняя цена трех ссылок на Coinbase + Money An + Firecoin, и это действительно справедливый роман.",
        tip2: "2. Вот 10 - минутный пример: первые 10 минут каждого периода - это время размещения заказа, следующие 2 минуты - цена тендера, следующая 1 минута - объявление цены тендера, тендер > цена тендера - это « повышение цены», Предложение - Угадайте, что цена 'падает', например: 00: 00 - 00: 15, это первый период, который начинается в этот день, в 00: 00 вы сделали BTC 'бычий' заказ, сумма заказа 1000 USDT. Когда время доходит до 00: 13, цена BTC составляет 35 000 долларов, тогда ваша маркировочная цена: 35 000 долларов, 00: 14 Объявленная цена результата составляет 35 001 долларов, тогда спекуляция результат 'растет', и вы догадываетесь, что это 'бум'. Поздравляю с догадками. Вы получите 950 долларов прибыли. Система будет расплачиваться за вас $1950 T, из которых $50 T является платформой, и вы получите прибыль в размере $950 T.",
        tip3: "3. Коэффициент 1: 0,95, почему бы не 1: 1? Платформа взимает 5% комиссионных..",
        tip4: "4. Результаты станут известны после окончания теста. Деньги, которые вы выиграли, были отданы вам проигравшими. Чтобы убедиться, что платформа не участвует в азартных играх между пользователями, если победителей больше, а победителей меньше, вам нужно подождать, пока победители не появятся, прежде чем раздавать бонусы. Например, когда вы ставите 100 000 USDT и думаете, что вы выиграли, вы должны получить 95 000 USDT. Однако, потеряв только 80 000 USDT, средств пула доставки недостаточно, чтобы заплатить вам 95 000 USDT. Вам нужно подождать, пока пул доставки покажет деньги проигравшего. Как только вы достигнете 15 000 USDT, платформа рассчитает ваши 95 000 бонусов.",
        tip5: "5. Если вы действительно не можете дождаться, чтобы быстро забрать бонус, вы можете быстро отправить, но быстрая доставка может получить только 25% от 95 000 USDT, а это означает, что вы можете взять только 2375 USDT, потому что быстрая доставка эквивалентна вашему обычному бонусу. Передача власти Платформе, Платформа должна нести относительный риск, поэтому Платформа должна вычитать огромные расходы.",
        tip6: "Этот тест не включает азартные игры между платформой и пользователем, и если вы обнаружите, что есть, вы можете сообщить об этом немедленно.",
    },
    home: {
        title: "Бибокскойн",
        // title: "Biboxcoin",
        Total_assets: "Общий остаток",
        Recharge: "Депозит",
        documentary: "Документальный фильм",
        transaction: "Сделки",
        IEO: "IEO",
        Lock_mining: "Горнодобывающая промышленность",
        Investment_Financing: "Инвестиционное финансирование",
        Currency_transfer: "Преобразование",
        Currency_swap: "Swap",
        Withdrawal: "Снятие",
        Blind_box: "Слепой ящик",
        Guess: "Угадай.",
        Daily_tasks: "Повседневные задания",
        Sign_task: "Задание подписи",
        Buy_coins_quickly: "Быстрая покупка монет.",
        Buy_quickly: "Быстрая покупка",
        day: "День",
        rise: "Повышение",
        Daily_yield: "Ежедневная норма прибыли",
        quotation: "Ссылки",
        Transaction_pair: "Имя",
        Latest_price: "Последние цены",
        Rise_fall: "Изменить",
    },
    ieo: {
        ieo: "IEO Подписка",
        Lock_up_cycle: "Цикл блокировки",
        day: "День",
        Closed: "Закрыть",
        Completed: "Завершено",
        Subscribed: "Подписаться",
        surplus: "Излишки",
        My_subscription: "Моя подписка.",
        Subscription_time: "Время подписки",
        currency: "Валюта",
        Requested_number: "Количество просьб",
        Pass_number: "Количество поездок",
        Market_time: "Рыночное время",
    },
    Invest: {
        conduct: "Осуществление финансовых операций",
        Special_area: "Особые зоны",
        Annualized_rate: "Годовой показатель",
        Linked_price: "Связанные цены",
        Remaining_share: "Оставшаяся доля",
        Position_duration: "Продолжительность работы",
        due_date: "Дата истечения",
        My_conduct: "Мое финансовое управление.",
        all: "Все",
        fail: "Неудача",
        Purchased: "Приобретено",
        settlement: "Соглашение",
        Completed: "Завершено",
    },
    Lockming: {
        Minimum_single: "Минимальная сумма",
        Lock_up_cycle: "Время ожидания",
        payout_time: "Время",
        everyday: "Ежедневно",
        Custody_funds: "Опека Фонды",
        Return_expiration: "Срок возврата",
        called_away: "Досрочное снятие средств",
        placeholder: "Введите содержимое",
        all: "Все",
        Estimated_income: "Прогнозируемые поступления",
        Available_assets: "Имеющиеся активы",
        subscription: "Подтверждение",
        Lock_mining: "Заблокировать раскопки",
        Funds_custody: "Фонды под опекой",
        Estimated_yield_today: "Сегодня ожидается прибыль",
        Accumulated_income: "Совокупные поступления",
        Orders_custody: "Заказ хостинга",
        Delegated_Order: "Приказ",
        Lock: "Замок",
        day: "День",
        Lock_list: "Блокировать список",
        Exited: "Выйти",
        Redeemed: "Выкуп.",
        have_hand: "Есть.",
        redeem: "Выкуп.",
        Amount_currency: "Количество",
        Daily_yield: "Ежедневная норма прибыли",
        Lockup_time: "Время блокировки",
        Expiration_time: "Срок годности",
        early_redemption: "Досрочное возмещение убытков",
    },
    Market: {
        Optional: "Выбор",
        Transaction_pair: "Имя",
        Latest_price: "Последние цены",
        place_search: "Ищите интересующие вас валюты / названия акций / коды",
    },
    Setting: {
        label_currency: "Выбрать валюту",
        place_currency: "Пожалуйста, выберите валюту",
        label_walletTitle: "Название кошелька",
        place_walletTitle: "Введите имя кошелька",
        label_walletAddress: "Адрес кошелька",
        place_walletAddress: "Введите адрес кошелька",
        Add_bankCard: "Добавить банковскую карту",
        label_name: "Настоящее имя",
        label_depositBank: "Банк - депозитарий",
        label_bankCard: "Номер банковской карты",
        label_beneficary_address: "Адрес получателя",
        label_payee_name: "Наименование получателя",
        label_bank_name: "Название банка",
        label_swift_code: "Банковский код",
        APP_download: "Сертификация MSB",
        label_currentPassword: "Текущий пароль",
        place_currentPassword: "Введите текущий пароль",
        label_newPassword: "Пароль входа",
        place_newPassword: "Введите пароль входа",
        label_newPassword1: "Деловой пароль",
        place_newPassword1: "Введите пароль транзакции.",
        label_confirmationPassword: "Подтвердить новый пароль",
        place_confirmationPassword: "Введите новый пароль.",
        payment_notice: "Примечание: начальным значением по умолчанию для пароля транзакции является текущий пароль входа",
        Real_mark1: "Услуги и ограничения :",
        Real_mark2: "Права доступа, доступные после завершения аутентификации:",
        Real_mark3: "Первичная проверка",
        Real_mark4: "Особенности и ограничения",
        Real_mark5: "Депозит NBSP & NBSP & NBSP & NBSP & NBSP & NBSP & NBSP & НБСП; Бесконечный",
        Real_mark5_1: "Снятие средств & NBSP & НБСП; 1 БТК",
        Real_mark5_2: "Торговля & NBSP & NBSP & NBSP & NBSP & NBSP & NBSP & NBSP & NBSP & NBSP & NBSP & НБСП; 2000 долл. США в день",
        Real_mark6: "Вторая расширенная проверка",
        Real_mark7: "Особенности и ограничения",
        Real_mark8: "Вклады & NBSP & NBSP & NBSP & NBSP & NBSP & NBSP & NBSP & НБСП; Бесконечный",
        Real_mark8_1: "Снятие средств & NBSP & НБСП; 100 BTC",
        Real_mark8_2: "Торговля & NBSP & NBSP & NBSP & NBSP & NBSP & NBSP & NBSP & NBSP & NBSP & NBSP & НБСП; 1 000 000 долл. США в день",
        Real_mark9: "Теплый совет: чтобы защитить средства пользователя, сначала выполните привязку почты и телефона, иначе ваша идентификация не пройдет.",
        Real_mark10: "Базовая проверка",
        Real_mark11: "Проверка завершена",
        Real_mark12: "Расширенная проверка",
        Real_mark13: "Проверка",
        label_realName: "Полное имя",
        message_realName: "Введите полное имя",
        label_cardNumber: "Номер удостоверения личности",
        message_cardNumber: "Пожалуйста, введите идентификационный номер.",
        upload_front: "Загрузка лицевой стороны удостоверения личности",
        upload_back: "Загрузить обратную сторону удостоверения личности",
        upload_people: "Фото, удостоверение личности в руке.",
        under_review: "В процессе рассмотрения",
        security_setting: "Изменить пароль",
        security_setting_title: "Справочная информация",
        my_bank: "Моя банковская карта.",
        Change_Loginpsd: "Изменить пароль входа",
        Change_transactionpsd: "Изменить пароль транзакции",
        email: "Электронная почта",
        my: "Мой.",
        walletAddress: "Адрес кошелька",
        Bank: "Банки",
        AddBank: "Добавить банк",
        Default_currency: "Фиатные валюты по умолчанию",
        language: "Язык",
        share: "Совокупность",
        logout: "Списание",
        qrcode: "Мой Qrcode",
        personal: "Личная информация",
        identity: "Идентификация",
        nickname: "Прозвище",
        nickname_tip: "2 - 10 символов, поддержка китайского и английского, цифры",
        Add_newAccount: "Добавить новый адрес",
        label_user: "Название клиента",
        label_number: "Банковский номер",
        label_branch: "Адрес банка",
        label_bank: "Название банка",
        label_swift: "Код Swift / Swift / BIC",
        label_address: "Адрес получателя",
    },
    Task: {
        daily_tasks: "Повседневные задания",
        activity_today: "Сегодняшнее мероприятие",
        task_list: "Список задач",
        weekly_tasks: "Еженедельные задания",
        activity: "Деятельность",
        undone: "Ундон",
        receive: "Прием",
        task_rules: "Правила миссии",
        task_rules1: "1: Активность накапливается суперпозиция в течение недели, автоматически очищается и перерасчет в 0: 00 утра каждую неделю.；",
        task_rules2: "2：Выполните все 4 ежедневных задания в день и получите 5 монет CCA после достижения уровня активности 100；",
        task_rules3: "3: Когда мероприятие достигает 350, вы можете получить 10 монет CCA；",
        task_rules4: "4: Когда активность достигает 700, вы можете испытать майнер N1 в течение 1 дня",
        sign_task: "Задачи входа в систему",
        Sign_everyDay: "Регистрация каждый день",
        CCA_coin: "Монеты CCA",
        signIn: "Регистрация",
        day: "День",
        daily_checkIn: "Ежедневное пребывание",
        put_away: "Убрать",
        Expand: "Расширение",
        signIn_now: "Войти немедленно",
        checkIn_rules: "Правила подписания",
        checkIn_rules1: "1：В течение 5 дней подряд будет получено 5 монет CCA；",
        checkIn_rules2: "2：В течение 14 дней он получит 20 монет CCA.；",
        checkIn_rules3: "3：Войдите в систему в течение 21 дня подряд и вознаградите шахтер N1 за три дня опыта；",
        checkIn_rules4: "4：После 24 дней непрерывного пребывания в номере можно получить 5 монет CCA в день.；",
    },
    Transaction: {
        delegate_List: "Список агентов",
        risk_rate: "Коэффициент риска",
        total_income: "Общий объем поступлений",
        oneClick_closing: "Закрыть одним нажатием",
        optional: "Выбор",
        all_closed: "Все склады закрыты.",
        flat_multiple: "Только один заказ и несколько заказов.",
        flat_empty: "Только плоские и пустые счета.",
        purchase: "Покупка",
        sellOut: "Продажа",
        market_price: "Рыночные цены",
        fixed_price: "Ограниченные цены",
        trading_hands: "Торговец",
        multiple: "Удвоение",
        bond: "Связи",
        Service_Charge: "Расходы на обслуживание",
        balance: "Равновесие",
        long: "Длинный",
        Short: "Короткий",
        Price: "Цены",
        number: "Цифры",
        type: "Тип",
        time: "Время",
        operation: "Деятельность",
        Transaction_pair: "Имя",
        direction: "Направление",
        search: "Поиск",
        Latest_price: "Последние цены",
        Rise_fall: "Изменить",
        entrust: "Поручение",
        all: "Все",
        entrust_time: "Время выполнения поручения",
        state: "Статус",
        Completed: "Завершено",
        air_completed: "Воздух завершен.",
        place_amount: "Сумма импорта",
        turnover: "Коэффициент изменения численности персонала",
        contract: "Контракты",
        option: "Параметры",
        coins: "Монеты",
        High_today: "Высокий сегодня.",
        Low_today: "Сегодня низкий",
        Entrusted_bill: "Доверительные документы",
        Buy_more: "Купить больше",
        Buy_less: "Покупайте меньше",
        buy: "Покупка",
        sell: "Продажа",
        crm_order: "Последовательность",
        Current_price: "Текущая доступная цена",
        expiration_time: "Выберите время истечения срока действия",
        Option_position: "История заказов",
        position: "Ситуация",
        history: "История",
        Purchase_price: "Цена заказа",
        Purchase_amount: "Количество",
        Order_duration: "Тип",
        Selling_time: "Время",
        Profit_loss: "Р / Л",
        Hand_count: "Количество рук",
        countdown: "Обратный отсчет",
        order_number: "Номер заказа",
        currency: "Валюта",
        reales_Number: "Фактические операции",
        total_price: "Все",
        close_price: "Цена закрытия",
        volume_trade: "Объем торговли",
        hand_fee: "Плата за услуги",
        continue_order: "Продолжайте заказывать",
    },
    footer: {
        home: "Домой",
        market: "Рынок",
        transaction: "Сделки",
        financial: "Финансовый",
        fund: "Мои активы.",
        rule: "Правило",
        select_language: "Пожалуйста, выберите язык."
    },
    common: {
        noData: "Нет никаких ФАТХ.",
        Exchange: "Обмен",
        Cancel: "Отменить",
        Confirm: "Подтверждение",
        Submit: "Представлено",
        more: "Больше.",
        add: "Добавить",
        Cover: "Прикрытие",
        Success: "Успех",
        portion: "Часть",
        day: "День",
        please_select: "Выберите",
        no_data: "Данные отсутствуют",
    },
    script: {
        error_msg: "Цифры / буквы",
        error_msg_address: "Неправильный адрес",
        error_msg_bank: "Длина должна быть не менее 6 - 20 символов.",
        error_msg_password: "Пароль должен иметь длину не менее 6 - 20 символов и содержать как минимум заглавные, нижние буквы и цифры.. ",
        register_noAccount: "Введите номер телефона или электронную почту.",
        register_captcha: "Введите код проверки.",
        send_phone_scuccess: "Отправить успешно!",
        register_success: "Регистрация успешно",
        please_enter_amount: "Введите сумму",
        please_enter_money: "Пожалуйста, введите деньги",
        success: "Успех!",
        bankId_msg: "Пожалуйста, выберите кошелек",
        amount_msg: "Введите сумму",
        password_msg: "Введите пароль.",
        password_withdraw_msg: "Введите пароль для снятия денег ",
        redemption_msg: "Подтверждение досрочного выкупа.?",
        tip: "Подсказка",
        redemption_success: "Успешный выкуп!",
        canceled: "Отменено",
        realname_submit_success: "Реальное имя успешно представлено!",
        logout_tip: "Вы уверены, что хотите его списать?？",
        login_tip: "Вы уверены, что хотите зарегистрироваться?",
        signIn: "Регистрация",
        closeout_tip: "Определить все точки закрытия?",
        closeout_success: "Успешное закрытие всех складов.!",
        cancel_tip: "Ты уверен, что отменишь??",
        notice: "Внимание!",
        insufficient_balance: "Недостаточный остаток!",
    },
};
